<template>
  <div class="app-calendar overflow-hidden border">
    <div class="facebook__login card p-3 text-center bg-info">
      <div class="text">
        <h3 class="text-white">Facebook Login</h3>
        <p class="text-white">Login with Facebook to view all your posts</p>
        <div id="app" class="d-flex justify-content-center">
          <v-facebook-login
            :app-id="appid[0].appId"
            v-model="model"
            @sdk-init="handleSdkInit"
            @login="onLogin"
          ></v-facebook-login>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div class="col-sm-12">
        <b-card class="mx-2">
          <b-card-title class="mb-1"> Legend</b-card-title>
          <b-card-body>
            <b-badge variant="primary" class="mr-1">Posted</b-badge>
            <b-badge variant="info" class="mr-1">Scheduled</b-badge>
          </b-card-body>
        </b-card>
      </div>

      <!-- Calendar -->
      <div class="col-sm-12 position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              :plugins="calendarPlugins"
              :events="fcEvents"
              :options="calendarOptions"
            />
          </div>
        </div>
      </div>
      <b-sidebar
        id="sidebar-right"
        bg-variant="white"
        right
        title="Update Job Card Status"
        backdrop
        shadow
        :visible="showSidebar"
        ref="sidebar"
      >
        <Sidebar :data="selectedItem" />
      </b-sidebar>
      <!-- Sidebar Overlay -->
    </div>
  </div>
</template>

<script>
import { BButton, BSidebar, BCard, BCardTitle, BBadge, BCardBody } from "bootstrap-vue";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import Sidebar from "./SidebarView.vue";
import VFacebookLogin from "vue-facebook-login-component";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    FullCalendar,
    BButton,
    BSidebar,
    Sidebar,
    BBadge,
    BCardBody,
    BCard,
    BCardTitle,
    VFacebookLogin,
  },

  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin],
        initialView: "dayGridMonth",
        events: [{}],
        editable: true,
        selectable: true,
        eventClick: this.handleEventClick,
      },
      showSidebar: false,
      appId: "",
      name: "",
      beakerToken: localStorage.getItem("accessToken"),
      email: "",
      personalID: "",
      picture: "",
      fb: null,
      message: "",
      pageid: "",
      FB: {},
      model: {},
      scope: {},
      pagePost: [],
      facebookToken: "",
      scheduled: [],
      userId: {},
      connected: false,
      selectedItem: [],
    };
  },
  computed: {
    ...mapGetters("marketingModule", {
      appid: "appid",
      loadingMarketing: "loading",
    }),
  },
  async mounted() {
    this.getAppIdAction();
  },
  methods: {
    ...mapActions("marketingModule", ["getAppIdAction"]),
    handleEventClick(clickInfo) {
      window.open(clickInfo.event._def.extendedProps.link, "_blank");
    },

    handleSdkInit({ FB, scope }) {
      this.FB = FB;
      this.scope = scope;
    },
    onLogin(response) {
      console.log(response);
      this.FB.api("/me/accounts", "GET", { fields: "name,access_token" }, (data) => {
        this.facebookToken = data.data[0].access_token;
        this.connected = true;
        localStorage.setItem("facebookToken", this.facebookToken);
        console.log(data);

        if (this.facebookToken) {
          // this.getPosts();
          this.getSchedules();
        }
      });
      this.FB.api("/me", "GET", { fields: "name" }, (data) => {
        this.userId = data;
      });
    },
    getAppId() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      };
      const url = process.env.VUE_APP_API_URL
      axios
        .get(url + "marketing/facebook/requestappid", config)
        .then((res) => {
          this.appId = res.data.result.token;
        });
    },
    getPosts() {
      let token = this.facebookToken;
      let access_token = this.beakerToken;
      this.loading = true;
      console.log(token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      const url = process.env.VUE_APP_API_URL
      axios
        .get(
          url + "marketing/facebook/getpagepost?token=" + token
        )
        .then((res) => {
          this.pagePost = res.data.result.data;
          for (let i = 0; i < this.pagePost.length; i++) {
            this.scheduled.push(this.pagePost[i]);
          }
        });
    },

    getSchedules() {
      let token = this.facebookToken;
      console.log(token);
      const url = process.env.VUE_APP_API_URL
      axios
        .get(url + "marketing/facebook/planner?token=" + token)
        .then((res) => {
          // for (let i = 0; i < res.data.result.data.length; i++) {
          //   this.scheduled.push(res.data.result.data[i]);
          // }
          this.scheduled = res.data.result.data;
          localStorage.setItem("scheduled", JSON.stringify(this.scheduled));
          console.log(this.scheduled);
          this.calendarOptions.events = this.scheduled.map((event) => ({
            id: event.id,
            title: event.message,
            start:
              event.scheduledPublishTime == null
                ? event.createdTime
                : new Date(event.scheduledPublishTime * 1000),
            textColor: "#fff",
            backgroundColor: event.scheduledPublishTime == null ? "#9FC54C" : "#4065AB",

            link: event.permalinkUrl,
          }));
        });
    },
  },
  handleEventClick(clickInfo) {
    this.showSidebar = !this.showSidebar;
    this.selectedItem = clickInfo.event;
  },
};


</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>

<style>
.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
}
</style>
