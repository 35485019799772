<template>
  <b-form @submit.prevent class="mx-1">
    <b-row>
      <b-col cols="12">
        <b-form-group label="Job Card Name" label-for="fh-usename">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="UserIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="fh-usename"
              placeholder="Username"
              v-model="data.title"
              readonly
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      {{ selectedOption }}
      <!-- email -->
      <b-col cols="12">
        <b-form-group label="Status" label-for="fh-email">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="ChevronDownIcon" />
            </b-input-group-prepend>
            <b-form-select v-model="selectedOption" :options="options" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Start Date" label-for="fh-email">
          <b-input-group class="input-group-merge">
            <b-form-datepicker
              id="example-datepicker"
              v-model="data.start"
              class="mb-1"
              readonly
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="End Date" label-for="fh-email">
          <b-input-group class="input-group-merge">
            <b-form-datepicker
              id="example-datepicker"
              v-model="data.end"
              class="mb-1"
              readonly
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <!-- submit and reset -->
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
          @click="UpdateJobCard()"
        >
          <b-spinner small v-if="loading" />
          Update
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormSelect,
  BFormDatepicker,
  BForm,
  BSpinner,
  BButton,
  BFormText,
  BFormDatalist,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BFormValidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    BFormDatepicker,
    BFormText,
    BButton,
    BFormDatalist,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: "",
    },
  },
  data() {
    return {
      selectedOption: null,
      options: [
        { value: 0, text: "Not Started" },
        { value: 1, text: "Started" },
        { value: 2, text: "Completed" },
      ],
      userId: "",
      occupationID: "",
    };
  },

  computed: {
    ...mapGetters("jobcardModule", {
      loading: "loading",
    }),
  },
  methods: {
    successAdd() {
      this.$swal({
        title: "Jobcard Updated!",
        text: "You have successfully updated a job card!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    // error
    errorAdd() {
      this.$swal({
        title: "Error!",
        text: " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    ...mapActions("jobcardModule", ["updateJobcardAction"]),
    UpdateJobCard() {
      let payload = {
        id: this.data.id,
        status: this.selectedOption,
      };
      console.log(payload);
      this.updateJobcardAction(payload)
        .then(() => {
          this.successAdd();
        })
        .catch(() => {
          this.errorAdd();
        });
    },
  },
};
</script>
